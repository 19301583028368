import React from "react"
import PropTypes from "prop-types"
import { kebabCase } from "lodash"
import { Helmet } from "react-helmet"
import { graphql, Link } from "gatsby"
import { Container, Badge } from "react-bootstrap"
import { FacebookProvider, Comments } from "react-facebook"

import Layout from "../components/Layout"
import ShareButtons from "../components/ShareButtons"
import Content, { HTMLContent } from "../components/Content"

export const BlogPostTemplate = ({
  content,
  contentComponent,
  tags,
  title,
  helmet,
  slug,
  url,
  urlImage,
}) => {
  const PostContent = contentComponent || Content

  return (
    <Container>
      {helmet || ""}

      <h2 className="mt-4 cl-vinho">{title}</h2>

      <Container>
        <PostContent className="cl-marinho post-container" content={content} />
      </Container>

      {tags && tags.length ? (
        <Container className="my-2">
          <h4 className="cl-vinho">Tags</h4>

          {tags.map(tag => (
            <Badge
              className="m-2 cl-fundo bg-marinho hovbg-vinho"
              key={tag}
              as={Link}
              to={`/tags/${kebabCase(tag)}/`}
            >
              {tag}
            </Badge>
          ))}
        </Container>
      ) : null}

      <ShareButtons
        postUrl={`https://amaehomeschooler.com.br/${slug}`}
        title={title}
        urlImage={urlImage}
      />

      <Container className="w-100">
        <FacebookProvider appId="2716102495293259">
          <Comments href={url} />
        </FacebookProvider>
      </Container>
    </Container>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { bloggerPost: post, site: title } = data

  return (
    <Layout>
      <BlogPostTemplate
        content={post.content}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate={`%s | ${title.siteMetadata.title}`}>
            <title>{`${post.title}`}</title>
            <meta name="title" content={`${post.title}`} />
          </Helmet>
        }
        tags={post.labels}
        title={post.title}
        id={post.id}
        slug={post.slug}
        url={post.url}
        urlImage={post.featuredImage.url}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    bloggerPost: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    bloggerPost(id: { eq: $id }) {
      id
      title
      slug
      url
      published
      content
      labels
      featuredImage {
        url
      }
      childMarkdownRemark {
        excerpt
        timeToRead
      }
    }
  }
`
