import React from "react"
import { Container } from "react-bootstrap"

import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share"

const ShareButtons = ({ postUrl, title, urlImage }) => (
  <Container fluid className="my-3 mx-0 p-0">
    <strong className="mx-1">Compartilhe:</strong>

    <WhatsappShareButton className="mx-1" url={postUrl} title={title}>
      <WhatsappIcon size={36} />
    </WhatsappShareButton>

    <TelegramShareButton className="mx-1" url={postUrl} title={title}>
      <TelegramIcon size={36} />
    </TelegramShareButton>

    <FacebookShareButton
      className="mx-1"
      url={postUrl}
      quote="Por A Mãe Homeschooler: uma mãe conservadora e homeschooler. Compartilho nosso homeschool (educação domiciliar), escrevo crônicas da maternidade, lifestyle, exponho algumas reflexões  além de compartilhar as melhores receitas. Leia também os outros textos e deixe seu comentário!"
      hashtag="#homeschooling"
    >
      <FacebookIcon size={36} />
    </FacebookShareButton>

    <TwitterShareButton
      className="mx-1"
      url={postUrl}
      title={title}
      hashtags={["homeschooling"]}
    >
      <TwitterIcon size={36} />
    </TwitterShareButton>
  </Container>
)
export default ShareButtons
